
.basic-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .basic-info-header {
        margin-top: var(--spacing-medium);
        margin-bottom: var(--spacing-medium);
        display: flex;
        .basic-info-button {
            display: flex;
            align-items: center;
            justify-content: end;
            width: 100%;
        }
    }
    .basic-info-table {
        // width: 80%;
    }
    .basic-info-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70vh;
    }
    
    .background-img {
        width: 100%;
    }
}