.website-footer {
    background-color: #232f3e;
    color: #fff;
    padding: 12px 0px;
    z-index: 1500;
    width: 100%;
    margin: 0;
    float: none;
    font-family: var(--font-family-base-ua1f64, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);

    .content-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .button-wrapper {
            display: flex;
            border: none;
            background-color: #232f3e;
            color:var(--white);
            
            &:not(:last-child) {
                border-right: 1px solid var(--white);
                padding: 0 var(--spacing-large);
            }
            &:last-child {
                padding-left: var(--spacing-large);
            }

            .beian-img {
                width: 16px;
                margin-right: 4px;
            }
        }

        .button-wrapper:hover {
            cursor: pointer;
            color: #ff9900;
        }
        
    }
    .content-wrapper > span {
        display: block;
        border-right: 1px solid var(--white);
        padding: 0 var(--spacing-large);
    }
    
}

@media only screen and (max-width: 990px) {
    .website-footer {
        .content-wrapper {
            flex-direction: column;
            .button-wrapper {
                border: none;
                &:not(:last-child) {
                    border: none;
                }
                &:last-child {
                    padding-left: 0px;
                }
            }
        }

        .content-wrapper > span, button {
            border: none;
            &:not(:last-child) {
                margin-bottom: 8px;
                text-align: center;
            }

           
        }
    }
}