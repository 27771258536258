.edit-company-info-page {
    .edit-company-button {
        display: flex;
        justify-content: flex-end;
    }

    .input-flex-line {
        display: flex;
        width: 75%;
    }
    .input-flex-line > div {
        flex-grow: 1;

        &:not(:last-child) {
            margin-right: var(--spacing-medium);
        }

        &:last-child {
            margin-right: 10px;
        }
    }

    @media screen and (max-width: 1068px) {
        .input-flex-line {
            width: 100%;
        }
    }
}