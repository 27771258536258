.website-navigation-top {
    background-color: var(--aws-squid-ink);
    color: var(--white);
    z-index: 1500;
    width: 100%;
    margin: 0;
    float: none;
    top: 0;
    right: 0;
    position: fixed;
    font-family: var(--font-family-base-ua1f64, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);

    .navigation-first-line {
        padding: 0px var(--spacing-xxx-large);
        width: auto;
        height: 60px;

        .header-logo img {
            width: auto;
            height: 40px;
            margin-right: 40px;
        }

        .navigation-first-line-right {
            display: flex;
            align-items: center;
        }

        .navigation-first-line-right>strong, a {
            margin-left: var(--spacing-xx-large);

            // not first child
            &:not(:first-child) {
                margin-right: var(--spacing-xx-large);
            }
        }
    }

    .navigation-second-line {
        padding: 0px var(--spacing-xxx-large);
        width: auto;
        height: 48px;

        .header-navigation-wrapper {
            display: flex;

            .header-navigation {
                white-space: nowrap;
            }

            .header-navigation> a {
                user-select: none;
                 text-decoration: none;

                &:not(:last-child) {
                    margin-right: var(--spacing-xx-large);
                }

            }
            .header-navigation>div {
                &:not(:last-child) {
                    margin-right: var(--spacing-xx-large);
                }
            }

        }
    }


    .header-navigation-button {
        position: relative;
        margin-right: var(--spacing-xx-small);
        color: var(--white);

        &:hover {
            cursor: pointer;
            color: var(--amazon-orange);
        }
    }

    .header-navigation-button-faded {
        background-image: linear-gradient(45deg, var(--white), rgba(173, 173, 173, 0.5));
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
    }

    .header-avatar-button {
        margin-left: 84px;
    }

    .profile-text {
        max-width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: var(--spacing-xx-small);
        margin-left: var(--spacing-x-small);

        &:hover {
            cursor: pointer;
            color: var(--amazon-orange);
        }
    }

    .profile-icon {
        height: 20px;
    }
}



// if window width < 1251px 
@media screen and (max-width: 1251px) {
    .website-navigation-top {
        .navigation-second-line {
            .header-navigation-wrapper {
                overflow-y: scroll;
                scrollbar-width: none;

                .header-navigation {
                    overflow-y: auto;
                    scrollbar-width: none;

                    &::-webkit-scrollbar {
                        display: none;
                    }
                }

            }
        }
    }
}


.dropdown-parent {

    .dropdown-container {
        position: fixed;
        width: auto;
        transition: all 0.3s;
        background-color: var(--white);
        z-index: 5001;
        border: 2px solid var(--grey-550);
        border-radius: var(--spacing-x-small);
        color: var(--grey-900);
    }



    .dropdown-child {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: auto;
        text-decoration: none;
        height: 22px;
        background-color: var(--white);
        border-radius: var(--spacing-x-small);
        padding: 4px 20px;
        color: var(--grey-900);

        &:hover {
            cursor: pointer;
            background-color: var(--grey-100);

            &:not(:first-child) {
                border-top: 2px solid var(--grey-550);
            }

            &:not(:last-child) {
                border-bottom: 2px solid var(--grey-550);
            }
        }
    }

    .dropdown-child-button {
        @extend .dropdown-child;
        height: 30px;
        width: 100%;
    }
    
}


.invisible-button {
    border: none;
    background-color: var(--aws-squid-ink);
    color: #fff;
    border-radius: 8px;

    &:hover {
        cursor: pointer;
    }
}

.header-layout {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.align-center {
    display: flex;
    align-items: center;
    padding: 0px;
}

.nav-text {
    color: #fff;
    user-select: none;
    text-decoration: none;
    font-weight: bold;

    &:hover {
        cursor: pointer;
        color: var(--amazon-orange);
    }
}