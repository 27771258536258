.mobile-header-wrapper {
    background-color: var(--aws-squid-ink);
    color: var(--white);
    z-index: 1500;
    width: 100%;
    margin: 0;
    float: none;
    top: 0;
    right: 0;
    position: fixed;
    font-family: var(--font-family-base-ua1f64, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);

    .mobile-header-icon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        margin: 0 var(--spacing-xxx-large);

        .mobile-header-logo {
            width: auto;
            height: 40px;
            margin-right: 40px;
        }
    }

    .mobile-header-icon-dropdown-open {
        color: var(--amazon-orange)
    }

    .mobile-header-create-button {
        height: 50px;
        margin: 0 var(--spacing-xxx-large);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mobile-header-dropdown {
        height: 100%;
        width: 100%;
        position: fixed;
        background-color: var(--aws-squid-ink);

        .profile-wrapper {
            display: flex;
            align-items: center;
            width: 97%;
        
            .profile-text {
                margin-right: var(--spacing-xx-small);
                margin-left: var(--spacing-x-small);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                
                &:hover {
                    cursor: pointer;
                    color:var(--amazon-orange);
                }
            }
    
            .profile-icon {
                height: 20px;
            }
    
        }
        
        .mobile-header-dropdown-item-wrapper {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid var(--grey-550);
            margin: 0 var(--spacing-xxx-large);

            .mobile-header-dropdown-item {
                height: 56px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: var(--white);
                text-decoration: none;
                font-weight: bold;

                &:hover {
                    cursor: pointer;
                    color: var(--amazon-orange);
                }
            }

            .mobile-header-dropdown-item-child {
                padding: 10px 20px;
                color: var(--white);
                text-decoration: none;

                &:hover {
                    cursor: pointer;
                    color: var(--amazon-orange);
                }
            }


        }

    }
}