.personal-info-container {
    display: flex;
    width: 100%;

    .img-line-container {
        border-right: 1px solid var(--grey-200);
        padding-right: 20px;
    }

    .info-line-container {
        width: 100%;
        overflow: hidden;
    }

    .info-line-container > div {
        height: auto;

        &:not(:last-child) {
            border-bottom: 1px solid var(--grey-200);
        }
    }
    .info-line {
        display: flex;
        align-items: flex-start;
        padding: 10px 0;
    }

    .info-line-title {
        min-width: 85px;
    }

    .info-line-content {
        margin-left: 150px;
        min-width: 70px;
        flex-grow: 1;
    }

    .personal-avatar {
        height: 128px;
        width: 128px;
    }

}

.personal-info-container > div {
    &:not(:last-child) {
        margin-right: 20px;
    }
}

@media screen and (max-width: 900px) {
    .personal-info-container {
        flex-wrap: wrap;

        .img-line-container {
            border-right: none;
        }
    }


}