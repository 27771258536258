@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;


:root {
    // spacing variables
    --spacing-xxx-small: 2px;
    --spacing-xx-small: 4px;
    --spacing-x-small: 8px;
    --spacing-small: 12px;
    --spacing-medium: 16px;
    --spacing-large: 20px;
    --spacing-x-large: 24px;
    --spacing-xx-large: 32px;
    --spacing-xxx-large: 40px;
  
    // colors
    --white: #ffffff;
    --grey-900: #16191f;
    --grey-600: #545b64;
    --grey-550: #687078;
    --grey-400: #aab7b8;
    --grey-200: #eaeded;
    --grey-150: #f2f3f3;
    --grey-100:	#fafafa;
    --green-600:	#1d8102;
    --green-100:	#f2f8f0;
    --blue-600:	#0073bb;
    --red-600:	#d13212;
    --red-100:	#fdf3f1;
    --orange-500:	#ec7211;
    --orange-700:	#dd6b10;
    --aws-squid-ink:	#232f3e;
    --amazon-orange:	#ff9900;

    --avatars-size: 96px;
  }