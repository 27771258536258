
.security-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    .security-duplicate-reminder {
        color: var(--grey-550);
        font-family: var(--font-family-base-ua1f64, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
        font-size: 14px; 
        line-height: 22px;
    }
    
    .security-header {
        margin-top: var(--spacing-medium);
        margin-bottom: var(--spacing-medium);
    }

    .background-img {
        width: 100%;
    }

    .common-questions {
        line-height: normal;
        font-weight: bold;
        color: var(--blue-600);
    }
    .common-questions:hover {
        cursor: pointer;
    }
}