.edit-personal-info-page {
    .edit-personal-button {
        display: flex;
        justify-content: flex-end;
    }
    .input-container {
        display: flex;
    }

    .top-avatar-container {
        display: flex;
        flex-direction: column;
    }

    .top-avatar-container > div {
        &:not(:last-child) {
            margin-bottom: var(--spacing-small);
        }
    }

    .avatar-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
    }
    .avatar-container > img {
        margin-right: var(--spacing-small);
        margin-bottom: var(--spacing-small);
        cursor: pointer;
    }
    .avatar-container > img:hover {
        opacity: 0.7;
    }

    .avatars {
        max-height: var(--avatars-size);
        height: auto;
    }

    .selected-avatar {
        @extend .avatars;
        border: 4px solid var(--amazon-orange);
        border-radius: 100%;
        height: calc(var(--avatars-size) - 8px);
        width: calc(var(--avatars-size) - 8px);
    }

    .input-flex-line {
        display: flex;
        width: 75%;
    }

    .input-flex-line > div {
        flex: 1;

        &:not(:last-child) {
            margin-right: var(--spacing-medium);
        }

        // &:last-child {
        //     margin-right: 10px;
        // }
    }
    .flex-line {
        display: flex;
        justify-content: space-between;
    }
    .flex-line-input {
        flex: 1;
    }
    .flex-line-button {
        margin-left: var(--spacing-small);
    }
    
    @media screen and (max-width: 900px) {
        .avatar-container {
            .avatars {
                height: 60px;
                width: 60px;
            }
        }
    }

    @media screen and (max-width: 600px) {
        .avatar-container {
            .avatars {
                height: 40px;
                width: 40px;
            }
        }
    }

    @media screen and (max-width: 1068px) {
        .input-flex-line {
            width: 100%;
            display: block;
        }

        .input-flex-line > div {
            flex: 1;
    
            &:not(:last-child) {
                margin-right: 0;
                margin-bottom: var(--spacing-medium);
            }
    
            // &:last-child {
            //     margin-right: 10px;
            // }
        }
    }


}

.modal-line {
    display: flex;
    justify-content: space-between;

    .modal-line-input {
        flex: 1;
    }
    .modal-line-button {
        margin-left: var(--spacing-small);
    }
 }